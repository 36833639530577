<template>
  <div>
    <entity-header
      class="mb-10"
      :title="contractor.name"
      :is-archived="contractor.isArchived"
      :back-route="backRoute"
      :loading="isLoading"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else class="pb-16" :schema="$options.schema" :data="contractor" />
  </div>
</template>

<script>
// Models
import { schema } from '@/schemas/contractor.view.schema';

// Services
import organizationsService from '@/services/organizations';

// Constants
import { CONTRACTORS } from '@/constants/routes';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';

export default {
  name: 'Buildings',

  components: { EntityHeader, ViewBuilder },

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      contractor: {},
    };
  },

  computed: {
    backRoute() {
      return { name: CONTRACTORS, query: { page: this.prevPage || 1 } };
    },
  },

  mounted() {
    this.isLoading = true;

    // @todo Добавить 404 в catch
    organizationsService
      .getContractorById(this.id)
      .then(contractor => {
        this.contractor = contractor;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  schema,
};
</script>
